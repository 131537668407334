export const Links = [
    {
        to: 'posts-list-landing',
        title: 'Landing Pages'
    },
    {
        to: 'posts-list-blog',
        title: 'Blog Posts'
    },
    {
        to: 'posts-list-essay',
        title: 'Essays'
    }
]
