export const landingFilters = {
    config: {
        rowsPerPage: true,
        paginationPage: true,
        status: true,
        search: true
    },
    initValues: {
        status: 'any'
    },
    lists: {
        fieldsList: [
            {
                value: 'post_slug',
                text: 'Url (slug)'
            },
            {
                value: 'post_title_primary',
                text: 'Title (h1)'
            },
            {
                value: 'post_meta_title',
                text: 'Title (META)'
            },
            {
                value: 'post_content_secondary',
                text: 'Content'
            }
        ],
        statusList: [
            {
                value: 'all',
                text: 'All'
            },
            {
                value: 'published',
                text: 'Published'
            },
            {
                value: 'suspended',
                text: 'Suspended'
            },
            {
                value: 'locked',
                text: 'Locked'
            },
            {
                value: 'unlocked',
                text: 'Unlocked'
            }
        ]
    }
}
