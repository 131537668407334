export const toolbarSettings = [
    {
        name: 'publish',
        permission: 'content/publish'
    },
    {
        name: 'unpublish',
        permission: 'content/publish'
    },
    {
        name: 'lock',
        permission: 'content/lock'
    },
    {
        name: 'unlock',
        permission: 'content/unlock'
    },
    {
        name: 'category',
        permission: 'content/landing/change-category'
    },
    {
        name: 'delete',
        permission: 'content/delete'
    }
]
